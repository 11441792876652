import React from 'react';
import { BarLoader } from 'react-spinners';
const LoadingScreen = () => {
  return (
    <div className='bg-[#fff] w-[100vw] h-[100vh] flex justify-center items-center'>
      <div className='flex justify-center items-center gap-3 flex-col'>
        <img src="blueLogo.svg" alt="" />
        <BarLoader color="#1B238D" width={200} height={5} />
      </div>
    </div>
  );
};

export default LoadingScreen;
