import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Home';
import Info from './Info';
import LoadingScreen from './LoadingScreen';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: loading ? <LoadingScreen /> : <Home />,
    },
    {
      path: '/info',
      element: <Info />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
