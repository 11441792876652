import React from 'react'
import Main from './Info/Main';
import Contact from "./Info/Contact";
import Outcome from './Info/Outcome';
import Course from './Info/Course';
import Footer from './Footer'
const Info = () => {
  return (
    <>
    
     <Main />
    <Contact />
    <div>
    <Outcome />

    </div>
    
    <Course />
    <Footer />

    </>
  )
}

export default Info;